import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentImagesState, ImageData, initialState } from './DocumentImagesState';

export const documentImagesSlice = createSlice({
    name: 'documentImages',
    initialState,
    reducers: {
        storeImageData: (state: DocumentImagesState, action: PayloadAction<ImageData>) => {
            state.images[`${action.payload.chatId}_${action.payload.messageId}_${action.payload.documentId}`] = action.payload.imageData;
        },
        removeImageData: (state: DocumentImagesState, action: PayloadAction<ImageData>) => {
            state.images[`${action.payload.chatId}_${action.payload.messageId}_${action.payload.documentId}`] = undefined;
        },
    },
});

export const { storeImageData, removeImageData } = documentImagesSlice.actions;

export default documentImagesSlice.reducer;