/*
 * Function to get file size in readable form.
 */
export function parseFileSize(size: string): number {
    const units = ["B", "KB", "MB", "GB", "TB"];
    const regex = /^(\d+(?:\.\d+)?)\s*(B|KB|MB|GB|TB)$/i;
    const match = size.match(regex);

    if (!match) {
        return 0;
    }

    const value = parseFloat(match[1]);
    const unit = match[2].toUpperCase();
    const unitIndex = units.indexOf(unit);

    if (unitIndex === -1) {
        return 0;
    }

    return value * Math.pow(1024, unitIndex);
};

export function getReadableByteString(bytes: number): string {
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    let i = 0;
    let dblBytes = bytes;

    while (i < sizes.length - 1 && bytes >= 1024) {
        dblBytes = bytes / 1024;
        bytes /= 1024;
        i++;
    }

    return `${dblBytes.toFixed(1)} ${sizes[i]}`;
};