import { Button } from '@fluentui/react-button';
import { Body1, Field, Input, Spinner, Switch, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Textarea, Tooltip, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { AddRegular, DocumentArrowUp20Regular } from '@fluentui/react-icons';
import { FormEvent, useRef, useState } from 'react';
import { Constants } from '../../../../Constants';
import { usePersonas } from '../../../../libs/hooks/usePersonas';
import { getRandomDefaultPersonaBackgroundColor } from '../../../../libs/utils/PersonaIconColorUtils';
import { store } from '../../../../redux/app/store';
import { SharedStyles } from '../../../../styles';
import { FluentIconPicker } from '../../../icon-picker/FluentIconPicker';
import { getReadableByteString } from '../../../utils/FileUtils';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    actions: {
        paddingTop: '10%',
    },
    createButton: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        width: 'fit-content',
    },
    textareaInput: {
        height: '100px',
    },
    error: {
        color: '#d13438',
    },
    iconQuestions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    content: {
        '& > div:not(:first-child)': {
            marginTop: '.5rem',
        },
    },
    uploadButton: {
        ...shorthands.margin('0', tokens.spacingHorizontalS, '0', '0'),
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
});

interface ICreatePersonaDialogProps {
    openMyPersonaTabHandler: () => void;
}

export const CreatePersonaDialog: React.FC<ICreatePersonaDialogProps> = ({ openMyPersonaTabHandler }) => {
    const personaNameRef = useRef<HTMLInputElement>(null);
    const classes = useClasses();
    const personas = usePersonas();

    const [personaName, setPersonaName] = useState('');
    const [personaDescription, setPersonaDescription] = useState('');
    const [personaInstructions, setPersonaInstructions] = useState('');
    const [personaIcon, setPersonaIcon] = useState('');
    const [chooseIcon, setChooseIcon] = useState(true);
    const [personaIconBackgroundColor, setPersonaIconBackgroundColor] = useState(
        getRandomDefaultPersonaBackgroundColor(),
    );
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const personaDocumentFileRef = useRef<HTMLInputElement | null>(null);
    const [personaPlugins] = useState(store.getState().personas.personaPlugins);
    const [pluginSwitches, setPluginSwitches] = useState(() => {
        const pluginSwitchesInitial: Record<string, boolean> = {};
        for (const plugin of personaPlugins) {
            pluginSwitchesInitial[plugin.pluginName] = false;
        }
        return pluginSwitchesInitial;
    });

    const handleSwitchChange = (plugin: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setPluginSwitches({
            ...pluginSwitches,
            [plugin]: event.target.checked,
        });
    };

    const iconChangePerPicker = (iconName: string, iconBackgroundColor: string) => {
        setPersonaIcon(iconName);
        setPersonaIconBackgroundColor(iconBackgroundColor);
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);
        const personaPluginsSelected = Object.keys(pluginSwitches).filter((plugin) => pluginSwitches[plugin]);
        void personas.createPersona(personaName, personaDescription, personaInstructions, personaIcon,
                chooseIcon, personaPluginsSelected, personaIconBackgroundColor, uploadedFiles)
            .then(() => {
                openMyPersonaTabHandler();
                setOpen(false);
                setErrorMessage('');
                setPersonaName('');
                setPersonaDescription('');
                setPersonaInstructions('');
                setPersonaIcon('');
                setChooseIcon(true);
                setPersonaIconBackgroundColor(getRandomDefaultPersonaBackgroundColor());
                setUploadedFiles([]);
                // Reset plugin switches
                setPluginSwitches(() => {
                    const pluginSwitchesInitial: Record<string, boolean> = {};
                    for (const plugin of personaPlugins) {
                        pluginSwitchesInitial[plugin.pluginName] = false;
                    }
                    return pluginSwitchesInitial;
                });
            })
            .catch(() => {
                setErrorMessage('Failed to create persona. Please try again later.');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogTrigger>
                <Button onClick={() => {}} appearance="primary" className={classes.createButton} icon={<AddRegular />}>
                    Create
                </Button>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Create A New Persona:</DialogTitle>
                        <DialogContent className={mergeClasses(classes.content, classes.scroll)}>
                            {errorMessage && <Body1 className={classes.error}>{errorMessage}</Body1>}
                            <Field label="Persona Name (Reference Only)">
                                <Input
                                    required
                                    type="text"
                                    value={personaName}
                                    onChange={(_e, input) => {
                                        setPersonaName(input.value);
                                    }}
                                    ref={personaNameRef}
                                />
                            </Field>
                            <Field label="Description">
                                <Textarea
                                    textarea={{ className: classes.textareaInput }}
                                    resize="vertical"
                                    value={personaDescription}
                                    onChange={(_e, input) => {
                                        setPersonaDescription(input.value);
                                    }}
                                    placeholder="Give the persona a description (optional)..."
                                />
                            </Field>
                            <Field label="Instructions">
                                <Textarea
                                    required
                                    textarea={{ className: classes.textareaInput }}
                                    resize="vertical"
                                    value={personaInstructions}
                                    onChange={(_e, input) => {
                                        setPersonaInstructions(input.value);
                                    }}
                                    placeholder="Customize the way the AI responds to you..."
                                />
                            </Field>
                            <Field label="Icon">
                                <div className={classes.iconQuestions}>
                                    <FluentIconPicker
                                        currentIconName={personaIcon}
                                        currentIconBackgroundColor={personaIconBackgroundColor}
                                        iconOnChange={iconChangePerPicker}
                                        focusOnClose={() => {
                                            // Focus on the input field after the icon picker closes
                                            // To fix default focus on background bug
                                            personaNameRef.current?.focus();
                                        }}
                                    />
                                    <Switch
                                        label="Automatically pick an icon if one isn't chosen"
                                        checked={chooseIcon}
                                        onChange={(_, data) => {
                                            setChooseIcon(data.checked);
                                        }}
                                    />
                                </div>
                            </Field>
                            <Field label="Documents">
                                <input
                                    type="file"
                                    ref={personaDocumentFileRef}
                                    style={{ display: 'none' }}
                                    accept={Constants.app.importTypes}
                                    multiple={true}
                                    onChange={() => {
                                        if (personaDocumentFileRef.current?.files?.length) {
                                            const filesArray = Array.from(personaDocumentFileRef.current.files);
                                            setUploadedFiles(filesArray);
                                        } else {
                                            setUploadedFiles([]);
                                        }
                                    }}
                                />
                                <div>
                                    <Tooltip content="Add documents to all chats with this persona" relationship="label">
                                        <Button
                                            className={classes.uploadButton}
                                            icon={<DocumentArrowUp20Regular />}
                                            onClick={() => personaDocumentFileRef.current?.click()}
                                        >
                                            Upload
                                        </Button>
                                    </Tooltip>
                                </div>
                                {uploadedFiles.length > 0 && (
                                    <Table arial-label="Persona documents" size="small">
                                        <TableHeader>
                                            <TableRow>
                                                <TableHeaderCell key="name">
                                                    Name
                                                </TableHeaderCell>
                                                <TableHeaderCell key="size">
                                                    Size
                                                </TableHeaderCell>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {uploadedFiles.map((item) => (
                                                <TableRow key={item.name}>
                                                    <TableCell>
                                                        <TableCellLayout>
                                                            {item.name}
                                                        </TableCellLayout>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableCellLayout>
                                                            {getReadableByteString(item.size)}
                                                        </TableCellLayout>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </Field>
                            {process.env.REACT_APP_PLUGIN_SELECTION_DISABLED !== 'true' && (
                                <Field label="Plugins">
                                    {personaPlugins.map((plugin) => (
                                        <Switch
                                            key={plugin.pluginName}
                                            label={`${plugin.pluginDisplayName} - ${plugin.pluginDescription}`}
                                            checked={pluginSwitches[plugin.pluginName]}
                                            onChange={handleSwitchChange(plugin.pluginName)}
                                        />
                                    ))}
                                </Field>
                            )}
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                type="submit"
                                icon={submitting ? <Spinner size="tiny" /> : undefined}
                                disabledFocusable={submitting}
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};
