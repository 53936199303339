export interface DocumentImagesState {
    images: Images;
}

export const initialState: DocumentImagesState = {
    images: {},
};

export type Images = Record<string, string | undefined>; //key: chatId_messageId_documentId, value: imageData URL string

export interface ImageData {
    chatId: string;
    messageId: string;
    documentId: string;
    imageData?: string;
}