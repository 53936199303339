import { Button, Tooltip, makeStyles } from '@fluentui/react-components';
import { ArrowClockwise24Regular } from '@fluentui/react-icons';
import { createSelector } from '@reduxjs/toolkit';
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Example } from './Example';

export interface ExampleModel {
    text: string;
    value: string;
}

const EXAMPLES: ExampleModel[] = [
    {
        text: 'Help me write an email to a colleague explaining the progress on our project.',
        value: 'Help me write an email to a colleague explaining the progress on our project.',
    },
    {
        text: 'Review this draft of a project update I wrote and suggest improvements.',
        value: 'Review this draft of a project update I wrote and suggest improvements.',
    },
    {
        text: 'I need to inform my team about a change in our meeting schedule. Can you help draft a concise note?',
        value: 'I need to inform my team about a change in our meeting schedule. Can you help draft a concise note?',
    },
    {
        text: 'I need to create a presentation about our quarterly sales. Can you help generate an outline?',
        value: 'I need to create a presentation about our quarterly sales. Can you help generate an outline?',
    },
    {
        text: 'Help me summarize this long report into key points for my team meeting.',
        value: 'Help me summarize this long report into key points for my team meeting.',
    },
    {
        text: 'I need to write a conclusion for my project report. Can you help with that?',
        value: 'I need to write a conclusion for my project report. Can you help with that?',
    },
    {
        text: "I'm looking for a new approach to improve our supply chain efficiency. Can you suggest some strategies?",
        value: "I'm looking for a new approach to improve our supply chain efficiency. Can you suggest some strategies?",
    },
    {
        text: 'Help me brainstorm potential causes for a recent decline in product quality.',
        value: 'Help me brainstorm potential causes for a recent decline in product quality.',
    },
    {
        text: 'Can you help me generate some discussion points for a meeting on cost-saving initiatives?',
        value: 'Can you help me generate some discussion points for a meeting on cost-saving initiatives?',
    },
    {
        text: 'I need to create a pivot table in Excel to summarize this data. Can you guide me through the steps?',
        value: 'I need to create a pivot table in Excel to summarize this data. Can you guide me through the steps?',
    },
    {
        text: 'Can you explain how to use VLOOKUP function in Excel with an example?',
        value: 'Can you explain how to use VLOOKUP function in Excel with an example?',
    },
    {
        text: "I'm trying to troubleshoot an issue with my software. Can you suggest some potential solutions?",
        value: "I'm trying to troubleshoot an issue with my software. Can you suggest some potential solutions?",
    },
    {
        text: 'Help me analyze this sales data and identify key trends.',
        value: 'Help me analyze this sales data and identify key trends.',
    },
    {
        text: 'I want to visualize some data as a bar chart. Can you guide me on how to do that in Excel?',
        value: 'I want to visualize some data as a bar chart. Can you guide me on how to do that in Excel?',
    },
    {
        text: 'Can you explain the difference between mean, median, and mode?',
        value: 'Can you explain the difference between mean, median, and mode?',
    },
    {
        text: 'I have a new project coming up. Can you help me create a project plan?',
        value: 'I have a new project coming up. Can you help me create a project plan?',
    },
    {
        text: 'Can you suggest some strategies for effective time management?',
        value: 'Can you suggest some strategies for effective time management?',
    },
    {
        text: 'Help me create a daily schedule that includes work tasks, breaks, and personal time.',
        value: 'Help me create a daily schedule that includes work tasks, breaks, and personal time.',
    },
    {
        text: 'I need to explain a complex idea to a non-technical audience. Can you help me draft a message?',
        value: 'I need to explain a complex idea to a non-technical audience. Can you help me draft a message?',
    },
    {
        text: 'I have a difficult conversation coming up with a team member. Can you suggest some tips?',
        value: 'I have a difficult conversation coming up with a team member. Can you suggest some tips?',
    },
    {
        text: 'Help me create a set of guidelines for effective communication within my team.',
        value: 'Help me create a set of guidelines for effective communication within my team.',
    },
    {
        text: "I'm looking for ways to increase customer engagement. Can you help brainstorm some ideas?",
        value: "I'm looking for ways to increase customer engagement. Can you help brainstorm some ideas?",
    },
    {
        text: 'Help me create a SWOT analysis for our latest product.',
        value: 'Help me create a SWOT analysis for our latest product.',
    },
    {
        text: 'I need to design a new marketing campaign. Can you help generate an outline?',
        value: 'I need to design a new marketing campaign. Can you help generate an outline?',
    },
    {
        text: "I'm interested in learning more about AI. Can you provide some resources?",
        value: "I'm interested in learning more about AI. Can you provide some resources?",
    },
    {
        text: 'I need to improve my public speaking skills. Can you suggest some exercises?',
        value: 'I need to improve my public speaking skills. Can you suggest some exercises?',
    },
    {
        text: 'Help me create a personal development plan focusing on leadership skills.',
        value: 'Help me create a personal development plan focusing on leadership skills.',
    },
    {
        text: 'Help me draft an SOR (Situation, Options, Recommendation)',
        value: 'Help me draft an SOR (Situation, Options, Recommendation)',
    },
    {
        text: 'Help me draft an email about a recent policy change.',
        value: 'Help me draft an email about a recent policy change.',
    },
    {
        text: 'What are the steps to create a histogram in Excel?',
        value: 'What are the steps to create a histogram in Excel?',
    },
    {
        text: 'Help me brainstorm ideas for reducing operational costs.',
        value: 'Help me brainstorm ideas for reducing operational costs.',
    },
    {
        text: 'How can I explain methanolysis molecular recycling technology to a non-technical audience?',
        value: 'How can I explain methanolysis molecular recycling technology to a non-technical audience?',
    },
    {
        text: 'Help me write a Python function to calculate the average of a list of numbers.',
        value: 'Help me write a Python function to calculate the average of a list of numbers.',
    },
    {
        text: 'I need to draft a report on market trends. Can you help create an outline?',
        value: 'I need to draft a report on market trends. Can you help create an outline?',
    },
    {
        text: 'Help me write a professional LinkedIn recommendation for a colleague.',
        value: 'Help me write a professional LinkedIn recommendation for a colleague.',
    },
    {
        text: 'I need to create a Gantt chart for my project. Can you guide me?',
        value: 'I need to create a Gantt chart for my project. Can you guide me?',
    },
    {
        text: 'How can I improve my Excel skills?',
        value: 'How can I improve my Excel skills?',
    },
    {
        text: "I'm planning a team building event. Can you help brainstorm some activities?",
        value: "I'm planning a team building event. Can you help brainstorm some activities?",
    },
    {
        text: 'Help me write a performance review for a team member.',
        value: 'Help me write a performance review for a team member.',
    },
    {
        text: 'How can I analyze data using pivot tables in Excel?',
        value: 'How can I analyze data using pivot tables in Excel?',
    },
    {
        text: "What's the best way to handle conflicts within a team?",
        value: "What's the best way to handle conflicts within a team?",
    },
    {
        text: 'Help me draft a client proposal for our new product.',
        value: 'Help me draft a client proposal for our new product.',
    },
    {
        text: "Can you explain the basics of machine learning in layman's terms?",
        value: "Can you explain the basics of machine learning in layman's terms?",
    },
    {
        text: 'I need to design an organizational chart for my team. Can you guide me?',
        value: 'I need to design an organizational chart for my team. Can you guide me?',
    },
    {
        text: 'Help me write a project status update for my manager.',
        value: 'Help me write a project status update for my manager.',
    },
    {
        text: "What's the best way to organize files and folders in SharePoint?",
        value: "What's the best way to organize files and folders in SharePoint?",
    },
    {
        text: 'Help me draft a professional bio for a conference.',
        value: 'Help me draft a professional bio for a conference.',
    },
    {
        text: 'I need tips on presenting to a virtual audience. Can you help?',
        value: 'I need tips on presenting to a virtual audience. Can you help?',
    },
    {
        text: 'Help me summarize this long article into key points.',
        value: 'Help me summarize this long article into key points.',
    },
    {
        text: "I need to explain our product's features to a customer. Can you help me draft a message?",
        value: "I need to explain our product's features to a customer. Can you help me draft a message?",
    },
    {
        text: 'How can I effectively delegate tasks to my team?',
        value: 'How can I effectively delegate tasks to my team?',
    },
    {
        text: 'Help me create an agenda for a project kick-off meeting.',
        value: 'Help me create an agenda for a project kick-off meeting.',
    },
    {
        text: 'Can you explain the concept of neural networks?',
        value: 'Can you explain the concept of neural networks?',
    },
    {
        text: 'Help me write an abstract for my research paper.',
        value: 'Help me write an abstract for my research paper.',
    },
    {
        text: "What's the best way to manage tasks in Azure DevOPs?",
        value: "What's the best way to manage tasks in Azure DevOPs?",
    },
    {
        text: 'Help me brainstorm strategies to increase online sales.',
        value: 'Help me brainstorm strategies to increase online sales.',
    },
    {
        text: 'Can you guide me on creating a financial forecast?',
        value: 'Can you guide me on creating a financial forecast?',
    },
    {
        text: 'How can I improve my business writing skills?',
        value: 'How can I improve my business writing skills?',
    },
    {
        text: 'Help me write a cover letter for a technical report.',
        value: 'Help me write a cover letter for a technical report.',
    },
    {
        text: 'I need to make a decision matrix. Can you guide me?',
        value: 'I need to make a decision matrix. Can you guide me?',
    },
    {
        text: 'Help me write a SQL query to retrieve data from a database.',
        value: 'Help me write a SQL query to retrieve data from a database.',
    },
    {
        text: 'What are the best practices for remote work?',
        value: 'What are the best practices for remote work?',
    },
    {
        text: 'Help me draft a risk assessment for my project.',
        value: 'Help me draft a risk assessment for my project.',
    },
    {
        text: 'Can you explain the fundamentals of cloud computing?',
        value: 'Can you explain the fundamentals of cloud computing?',
    },
    {
        text: 'Help me write a press release for our new product launch.',
        value: 'Help me write a press release for our new product launch.',
    },
    {
        text: "What's the best way to manage emails and prevent inbox overload?",
        value: "What's the best way to manage emails and prevent inbox overload?",
    },
    {
        text: "Help me write a feedback email for a vendor's service.",
        value: "Help me write a feedback email for a vendor's service.",
    },
    {
        text: 'How can I make my PowerPoint presentations more engaging?',
        value: 'How can I make my PowerPoint presentations more engaging?',
    },
    {
        text: 'Help me write a JavaScript function to filter an array of objects.',
        value: 'Help me write a JavaScript function to filter an array of objects.',
    },
    {
        text: 'What are the basics of project management?',
        value: 'What are the basics of project management?',
    },
    {
        text: 'Help me draft a company-wide communication about an upcoming system outage.',
        value: 'Help me draft a company-wide communication about an upcoming system outage.',
    },
    {
        text: 'How can I effectively manage a virtual team?',
        value: 'How can I effectively manage a virtual team?',
    },
    {
        text: 'Help me write a business case for a new project initiative.',
        value: 'Help me write a business case for a new project initiative.',
    },
    {
        text: 'Can you guide me on creating a comprehensive project budget?',
        value: 'Can you guide me on creating a comprehensive project budget?',
    },
    {
        text: "What's the best way to create an effective to-do list?",
        value: "What's the best way to create an effective to-do list?",
    },
    {
        text: 'Help me write a customer service response to a complaint.',
        value: 'Help me write a customer service response to a complaint.',
    },
    {
        text: 'How can I improve my negotiation skills?',
        value: 'How can I improve my negotiation skills?',
    },
    {
        text: 'Help me brainstorm marketing ideas for a new product launch.',
        value: 'Help me brainstorm marketing ideas for a new product launch.',
    },
    {
        text: 'What are the best practices for online team collaboration?',
        value: 'What are the best practices for online team collaboration?',
    },
    {
        text: 'Help me draft an executive summary for a business plan.',
        value: 'Help me draft an executive summary for a business plan.',
    },
    {
        text: 'What formula in excel could I use to sum up a column using two different criteria?',
        value: 'What formula in excel could I use to sum up a column using two different criteria?',
    },
    {
        text: 'How would I prepare a presentation to show off Net Present Value?',
        value: 'How would I prepare a presentation to show off Net Present Value?',
    },
    {
        text: 'How can I make this email sound more professional?',
        value: 'How can I make this email sound more professional?',
    },
    {
        text: 'Write a sample job description for a Pricing Manager supporting the Performance Films business',
        value: 'Write a sample job description for a Pricing Manager supporting the Performance Films business',
    },
    {
        text: 'How do I construct a McCabe-Thiele diagram?',
        value: 'How do I construct a McCabe-Thiele diagram?',
    },
    {
        text: 'Identify and analyze key competitors in the PET recycling industry.',
        value: 'Identify and analyze key competitors in the PET recycling industry.',
    },
    {
        text: 'Help me come up with a cool name for this new project.',
        value: 'Help me come up with a cool name for this new project.',
    },
    {
        text: 'Which deep learning library should I use: PyTorch or TensorFlow?',
        value: 'Which deep learning library should I use: PyTorch or TensorFlow?',
    },
    {
        text: "What are second-order reactions? Explain like I'm 5.",
        value: "What are second-order reactions? Explain like I'm 5.",
    },
    {
        text: 'What are the key points in this email?',
        value: 'What are the key points in this email?',
    },
    {
        text: 'Help me reply to an email including some key points that I will provide',
        value: 'Help me reply to an email including some key points that I will provide',
    },
    {
        text: 'How do I write an effective job description?',
        value: 'How do I write an effective job description?',
    },
    {
        text: 'Help me outline a strategic plan for next year.',
        value: 'Help me outline a strategic plan for next year.',
    },
    {
        text: 'What are some tips for managing a multi-generational team?',
        value: 'What are some tips for managing a multi-generational team?',
    },
    {
        text: 'How can I write a compelling project proposal?',
        value: 'How can I write a compelling project proposal?',
    },
    {
        text: 'Help me draft an employee onboarding plan.',
        value: 'Help me draft an employee onboarding plan.',
    },
    {
        text: 'What are some best practices for team communication?',
        value: 'What are some best practices for team communication?',
    },
    {
        text: 'Help me write a memo to my team about an important upcoming meeting.',
        value: 'Help me write a memo to my team about an important upcoming meeting.',
    },
    {
        text: 'Can you explain the concept of data normalization?',
        value: 'Can you explain the concept of data normalization?',
    },
    {
        text: 'Help me write a thank you email to a client for their business.',
        value: 'Help me write a thank you email to a client for their business.',
    },
    {
        text: 'Can you guide me on creating an inventory tracking system in Excel?',
        value: 'Can you guide me on creating an inventory tracking system in Excel?',
    },
    {
        text: 'What are some tips for efficient meeting management?',
        value: 'What are some tips for efficient meeting management?',
    },
    {
        text: 'Help me brainstorm ideas for improving team productivity.',
        value: 'Help me brainstorm ideas for improving team productivity.',
    },
    {
        text: 'How do I write a sales pitch for our new product?',
        value: 'How do I write a sales pitch for our new product?',
    },
    {
        text: 'Help me draft an email announcing a new hire to the team.',
        value: 'Help me draft an email announcing a new hire to the team.',
    },
    {
        text: 'What do i need to create an effective SOR (Situation, Options, Recommendation)',
        value: 'What do i need to create an effective SOR (Situation, Options, Recommendation)',
    },
    {
        text: 'Can you explain the principles of Agile project management?',
        value: 'Can you explain the principles of Agile project management?',
    },
    {
        text: 'Help me outline a training program for new sales hires.',
        value: 'Help me outline a training program for new sales hires.',
    },
    {
        text: 'Help me draft a customer satisfaction survey.',
        value: 'Help me draft a customer satisfaction survey.',
    },
    {
        text: 'What are some techniques for effective public speaking?',
        value: 'What are some techniques for effective public speaking?',
    },
    {
        text: 'Help me write a JavaScript function to sort an array of numbers.',
        value: 'Help me write a JavaScript function to sort an array of numbers.',
    },
    {
        text: 'How do I write a constructive feedback email to a team member?',
        value: 'How do I write a constructive feedback email to a team member?',
    },
    {
        text: 'Help me brainstorm topics for our next company-wide training.',
        value: 'Help me brainstorm topics for our next company-wide training.',
    },
    {
        text: 'What are some tips for effective time management?',
        value: 'What are some tips for effective time management?',
    },
    {
        text: 'Can you explain the principles of Lean manufacturing?',
        value: 'Can you explain the principles of Lean manufacturing?',
    },
    {
        text: 'Help me write a Python script to read and parse a CSV file.',
        value: 'Help me write a Python script to read and parse a CSV file.',
    },
    {
        text: 'What are the steps to create a waterfall chart in Excel?',
        value: 'What are the steps to create a waterfall chart in Excel?',
    },
    {
        text: 'Help me write a performance review for an employee.',
        value: 'Help me write a performance review for an employee.',
    },
    {
        text: 'Can you explain the process of peer review in scientific research?',
        value: 'Can you explain the process of peer review in scientific research?',
    },
    {
        text: 'Help me draft an employee satisfaction survey.',
        value: 'Help me draft an employee satisfaction survey.',
    },
    {
        text: 'How do I write a competitive analysis report?',
        value: 'How do I write a competitive analysis report?',
    },
    {
        text: 'Help me write an email announcing a change in company policy.',
        value: 'Help me write an email announcing a change in company policy.',
    },
    {
        text: 'What are the principles of design thinking?',
        value: 'What are the principles of design thinking?',
    },
    {
        text: 'Help me outline a marketing plan for our product launch.',
        value: 'Help me outline a marketing plan for our product launch.',
    },
    {
        text: 'How do I create an organizational chart in PowerPoint?',
        value: 'How do I create an organizational chart in PowerPoint?',
    },
    {
        text: 'Help me write a welcome email for a new team member.',
        value: 'Help me write a welcome email for a new team member.',
    },
    {
        text: 'What are some best practices for virtual presentations?',
        value: 'What are some best practices for virtual presentations?',
    },
    {
        text: 'Can you explain the steps of the scientific method?',
        value: 'Can you explain the steps of the scientific method?',
    },
    {
        text: 'Help me write a Python script to scrape data from a website.',
        value: 'Help me write a Python script to scrape data from a website.',
    },
    {
        text: 'How do I create a balanced scorecard for my team?',
        value: 'How do I create a balanced scorecard for my team?',
    },
    {
        text: 'Help me draft a quarterly performance report.',
        value: 'Help me draft a quarterly performance report.',
    },
    {
        text: 'What are some strategies for conflict resolution in a team?',
        value: 'What are some strategies for conflict resolution in a team?',
    },
    {
        text: 'How do I write an RFP (request for proposal)?',
        value: 'How do I write an RFP (request for proposal)?',
    },
    {
        text: 'Help me outline a business continuity plan.',
        value: 'Help me outline a business continuity plan.',
    },
    {
        text: 'Help me write a project status update email.',
        value: 'Help me write a project status update email.',
    },
    {
        text: 'Can you explain the benefits of cloud computing?',
        value: 'Can you explain the benefits of cloud computing?',
    },
    {
        text: 'Help me create a project risk assessment.',
        value: 'Help me create a project risk assessment.',
    },
    {
        text: 'How do I write an executive summary for a research paper?',
        value: 'How do I write an executive summary for a research paper?',
    },
    {
        text: 'Help me write a letter of recommendation for a colleague.',
        value: 'Help me write a letter of recommendation for a colleague.',
    },
    {
        text: 'What are some strategies for improving customer retention?',
        value: 'What are some strategies for improving customer retention?',
    },
    {
        text: 'Help me write a Python script to automate a simple task.',
        value: 'Help me write a Python script to automate a simple task.',
    },
    {
        text: 'How do I create a financial forecast for my business?',
        value: 'How do I create a financial forecast for my business?',
    },
    {
        text: 'Help me draft an email negotiating a contract with a vendor.',
        value: 'Help me draft an email negotiating a contract with a vendor.',
    },
    {
        text: 'Can you help me understand price volume curves?',
        value: 'Can you help me understand price volume curves?',
    },
    {
        text: 'Help me create an agenda for a strategic planning meeting.',
        value: 'Help me create an agenda for a strategic planning meeting.',
    },
    {
        text: 'Can you explain the principles of sustainable development?',
        value: 'Can you explain the principles of sustainable development?',
    },
    {
        text: 'Help me write a SQL query to extract data from a database.',
        value: 'Help me write a SQL query to extract data from a database.',
    },
    {
        text: 'Help me draft an email asking for an extension on a deadline.',
        value: 'Help me draft an email asking for an extension on a deadline.',
    },
    {
        text: 'What are the steps to create a Gantt chart in Excel?',
        value: 'What are the steps to create a Gantt chart in Excel?',
    },
    {
        text: 'Help me write a business case for a new technology investment.',
        value: 'Help me write a business case for a new technology investment.',
    },
    {
        text: 'Help me outline a sales strategy for a new market.',
        value: 'Help me outline a sales strategy for a new market.',
    },
    {
        text: 'How do I write a performance improvement plan for an employee?',
        value: 'How do I write a performance improvement plan for an employee?',
    },
    {
        text: 'Help me draft a policy change announcement email.',
        value: 'Help me draft a policy change announcement email.',
    },
    {
        text: 'What are the best practices for data visualization?',
        value: 'What are the best practices for data visualization?',
    },
    {
        text: 'Help me write a professional bio for a conference presentation.',
        value: 'Help me write a professional bio for a conference presentation.',
    },
    {
        text: 'Can you explain the process of natural language processing?',
        value: 'Can you explain the process of natural language processing?',
    },
    {
        text: 'How do AI Assistants like Eastman Copilot work?',
        value: 'How do AI Assistants like Eastman Copilot work?',
    },
    {
        text: 'How do I create a content marketing strategy?',
        value: 'How do I create a content marketing strategy?',
    },
    {
        text: 'Help me write some good prompts to get the most out of Eastman Copilot.',
        value: 'Help me write some good prompts to get the most out of Eastman Copilot.',
    },
    {
        text: 'What are some effective stress management techniques?',
        value: 'What are some effective stress management techniques?',
    },
    {
        text: 'Help me practice a difficult sales call with my customers.',
        value: 'Help me practice a difficult sales call with my customers.',
    },
    {
        text: 'Can you explain the principles of quantum computing?',
        value: 'Can you explain the principles of quantum computing?',
    },
    {
        text: 'Help me draft a response to a customer complaint.',
        value: 'Help me draft a response to a customer complaint.',
    },
    {
        text: 'How do I create a personal development plan?',
        value: 'How do I create a personal development plan?',
    },
    {
        text: 'Help me write an email to schedule a meeting with my team.',
        value: 'Help me write an email to schedule a meeting with my team.',
    },
    {
        text: 'How can customer segmentation help in pricing?',
        value: 'How can customer segmentation help in pricing?',
    },
    {
        text: 'Can you explain the concept of blockchain technology?',
        value: 'Can you explain the concept of blockchain technology?',
    },
    {
        text: 'What are some tips for facilitating effective brainstorming sessions?',
        value: 'What are some tips for facilitating effective brainstorming sessions?',
    },
    {
        text: "Help me write a professional auto-response email for when I'm out of office.",
        value: "Help me write a professional auto-response email for when I'm out of office.",
    },
    {
        text: 'How do I create a PivotTable in Excel?',
        value: 'How do I create a PivotTable in Excel?',
    },
    {
        text: 'What is the formula to add a column in Excel?',
        value: 'What is the formula to add a column in Excel?',
    },
    {
        text: 'Help me design a professional PowerPoint presentation.',
        value: 'Help me design a professional PowerPoint presentation.',
    },
    {
        text: 'How do I create a line chart in Excel?',
        value: 'How do I create a line chart in Excel?',
    },
    {
        text: 'What are some useful PowerBI features I should know?',
        value: 'What are some useful PowerBI features I should know?',
    },
    {
        text: 'How do I use VLOOKUP in Excel?',
        value: 'How do I use VLOOKUP in Excel?',
    },
    {
        text: 'Help me design a visually appealing PowerPoint slide.',
        value: 'Help me design a visually appealing PowerPoint slide.',
    },
    {
        text: 'How do I create a pie chart in Excel?',
        value: 'How do I create a pie chart in Excel?',
    },
    {
        text: 'How do I create a dashboard in PowerBI?',
        value: 'How do I create a dashboard in PowerBI?',
    },
    {
        text: 'What is the formula to find the average in Excel?',
        value: 'What is the formula to find the average in Excel?',
    },
    {
        text: 'How do I use slicers with a PivotTable in Excel?',
        value: 'How do I use slicers with a PivotTable in Excel?',
    },
    {
        text: 'Help me with some tips for effective PowerPoint transitions.',
        value: 'Help me with some tips for effective PowerPoint transitions.',
    },
    {
        text: 'How do I create a scatter plot in Excel?',
        value: 'How do I create a scatter plot in Excel?',
    },
    {
        text: 'What are the steps to connect PowerBI to SQL Server?',
        value: 'What are the steps to connect PowerBI to SQL Server?',
    },
    {
        text: 'How do I use conditional formatting in Excel?',
        value: 'How do I use conditional formatting in Excel?',
    },
    {
        text: 'How do I calculate percentage change in Excel?',
        value: 'How do I calculate percentage change in Excel?',
    },
    {
        text: 'Help me create a compelling PowerPoint presentation outline.',
        value: 'Help me create a compelling PowerPoint presentation outline.',
    },
    {
        text: 'How do I create a bar chart in Excel?',
        value: 'How do I create a bar chart in Excel?',
    },
    {
        text: 'How do I import data into PowerBI?',
        value: 'How do I import data into PowerBI?',
    },
    {
        text: 'What is the formula to split text in Excel?',
        value: 'What is the formula to split text in Excel?',
    },
    {
        text: 'How do I create a calculated column in a PivotTable?',
        value: 'How do I create a calculated column in a PivotTable?',
    },
    {
        text: 'Help me create a PowerPoint presentation with speaker notes.',
        value: 'Help me create a PowerPoint presentation with speaker notes.',
    },
    {
        text: 'How do I create a histogram in Excel?',
        value: 'How do I create a histogram in Excel?',
    },
    {
        text: 'How do I create a report in PowerBI?',
        value: 'How do I create a report in PowerBI?',
    },
    {
        text: 'How do I use the COUNTIF function in Excel?',
        value: 'How do I use the COUNTIF function in Excel?',
    },
    {
        text: 'What are the steps to refresh a PivotTable in Excel?',
        value: 'What are the steps to refresh a PivotTable in Excel?',
    },
    {
        text: 'Help me design a PowerPoint slide deck that tells a story.',
        value: 'Help me design a PowerPoint slide deck that tells a story.',
    },
    {
        text: 'How do I create a bubble chart in Excel?',
        value: 'How do I create a bubble chart in Excel?',
    },
    {
        text: 'How do I create a drill-through in PowerBI?',
        value: 'How do I create a drill-through in PowerBI?',
    },
    {
        text: 'How do I use the SUMIFS function in Excel?',
        value: 'How do I use the SUMIFS function in Excel?',
    },
    {
        text: 'How do I apply filters to a PivotTable in Excel?',
        value: 'How do I apply filters to a PivotTable in Excel?',
    },
    {
        text: 'Help me with the best practices for PowerPoint design.',
        value: 'Help me with the best practices for PowerPoint design.',
    },
    {
        text: 'How do I create a Gantt chart in Excel?',
        value: 'How do I create a Gantt chart in Excel?',
    },
    {
        text: 'How do I create a visualization in PowerBI?',
        value: 'How do I create a visualization in PowerBI?',
    },
    {
        text: 'How do I use the CONCATENATE function in Excel?',
        value: 'How do I use the CONCATENATE function in Excel?',
    },
    {
        text: 'How do I change the layout of a PivotTable in Excel?',
        value: 'How do I change the layout of a PivotTable in Excel?',
    },
    {
        text: 'Help me create a PowerPoint template.',
        value: 'Help me create a PowerPoint template.',
    },
    {
        text: 'How do I create a stacked bar chart in Excel?',
        value: 'How do I create a stacked bar chart in Excel?',
    },
    {
        text: 'How do I add a custom column in PowerBI?',
        value: 'How do I add a custom column in PowerBI?',
    },
    {
        text: 'How do I use the INDEX MATCH function in Excel?',
        value: 'How do I use the INDEX MATCH function in Excel?',
    },
    {
        text: 'How do I create a PivotChart in Excel?',
        value: 'How do I create a PivotChart in Excel?',
    },
    {
        text: "Help me design a PowerPoint slide that's not boring.",
        value: "Help me design a PowerPoint slide that's not boring.",
    },
    {
        text: 'How do I create a combination chart in Excel?',
        value: 'How do I create a combination chart in Excel?',
    },
    {
        text: 'How do I use the Quick Measure feature in PowerBI?',
        value: 'How do I use the Quick Measure feature in PowerBI?',
    },
    {
        text: 'How do I use the IF function in Excel?',
        value: 'How do I use the IF function in Excel?',
    },
    {
        text: 'What are the best practices for PivotTable design in Excel?',
        value: 'What are the best practices for PivotTable design in Excel?',
    },
    {
        text: 'Help me create a PowerPoint presentation that is visually engaging.',
        value: 'Help me create a PowerPoint presentation that is visually engaging.',
    },
    {
        text: 'How do I create a waterfall chart in Excel?',
        value: 'How do I create a waterfall chart in Excel?',
    },
    {
        text: 'How do I use PowerBI to analyze data?',
        value: 'How do I use PowerBI to analyze data?',
    },
    {
        text: 'How do I use the SUBTOTAL function in Excel?',
        value: 'How do I use the SUBTOTAL function in Excel?',
    },
    {
        text: 'How do I calculate the median in a PivotTable in Excel?',
        value: 'How do I calculate the median in a PivotTable in Excel?',
    },
    {
        text: 'Help me design a PowerPoint template that reflects my company branding.',
        value: 'Help me design a PowerPoint template that reflects my company branding.',
    },
    {
        text: 'How do I create a radar chart in Excel?',
        value: 'How do I create a radar chart in Excel?',
    },
    {
        text: 'What are the basics of creating a PowerBI report?',
        value: 'What are the basics of creating a PowerBI report?',
    },
    {
        text: 'How do I use the CHAR function in Excel?',
        value: 'How do I use the CHAR function in Excel?',
    },
    {
        text: 'How do I create a custom calculated field in a PivotTable in Excel?',
        value: 'How do I create a custom calculated field in a PivotTable in Excel?',
    },
    {
        text: 'Help me create an effective PowerPoint presentation for an important business meeting.',
        value: 'Help me create an effective PowerPoint presentation for an important business meeting.',
    },
    {
        text: 'How do I create a 3D chart in Excel?',
        value: 'How do I create a 3D chart in Excel?',
    },
    {
        text: 'What are some advanced features of PowerBI that I should know?',
        value: 'What are some advanced features of PowerBI that I should know?',
    },
    {
        text: 'How do I use the PROPER function in Excel?',
        value: 'How do I use the PROPER function in Excel?',
    },
    {
        text: 'How do I sort data in a PivotTable in Excel?',
        value: 'How do I sort data in a PivotTable in Excel?',
    },
    {
        text: 'Help me with ideas for a creative PowerPoint presentation.',
        value: 'Help me with ideas for a creative PowerPoint presentation.',
    },
    {
        text: 'How do I create a treemap chart in Excel?',
        value: 'How do I create a treemap chart in Excel?',
    },
    {
        text: 'How do I use PowerBI to create interactive reports?',
        value: 'How do I use PowerBI to create interactive reports?',
    },
    {
        text: 'How do I use the LEFT function in Excel?',
        value: 'How do I use the LEFT function in Excel?',
    },
    {
        text: 'How do I group data in a PivotTable in Excel?',
        value: 'How do I group data in a PivotTable in Excel?',
    },
    {
        text: 'Help me create a PowerPoint presentation that is clear and concise.',
        value: 'Help me create a PowerPoint presentation that is clear and concise.',
    },
    {
        text: 'How do I create a sunburst chart in Excel?',
        value: 'How do I create a sunburst chart in Excel?',
    },
    {
        text: 'How do I create a slicer in PowerBI?',
        value: 'How do I create a slicer in PowerBI?',
    },
    {
        text: 'How do I use the RIGHT function in Excel?',
        value: 'How do I use the RIGHT function in Excel?',
    },
];

// Fisher-Yates shuffle algorithm
function shuffleArray(array: ExampleModel[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

const useClasses = makeStyles({
    container: {
        display: 'flex',
        //flexDirection: 'column',
        alignItems: 'stretch',
    },
    iconWrapper: {
        paddingTop: '10px',
        //width: '100%',
        //display: 'flex',
        //justifyContent: 'flex-end',
    },
    examplesNavList: {
        listStylePosition: 'initial',
        listStyleImage: 'initial',
        listStyleType: 'none',
        paddingLeft: '10px',
        paddingRight: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '10px',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
        justifyContent: 'center',
        overflowY: 'hidden',
    },
    exampleItemContainer: {
        paddingBottom: '10px',
    }
});

interface Props {
    onExampleClicked: (value: string) => void;
    scrollViewTargetRef: React.RefObject<HTMLDivElement>;
    isSmallWindow: boolean;
}

export interface SetInitialWindowSizeHandle {
    setInitialWindowSize: () => void;
}

const ExampleList: ForwardRefRenderFunction<SetInitialWindowSizeHandle, Props> = (
    { onExampleClicked, scrollViewTargetRef, isSmallWindow }: Props,
    setInitialWindowSizeRef: React.ForwardedRef<SetInitialWindowSizeHandle>,
) => {
    const classes = useClasses();
    const selectedId = useAppSelector((state: RootState) => state.conversations.selectedId);
    const personaId = useAppSelector((state: RootState) => state.conversations.conversations[selectedId].personaId);
    const selectPersonaById = (state: RootState, personaId: string | undefined) =>
        state.personas.personas[personaId ?? ''];
    const selectPersonaExamplePrompts = createSelector([selectPersonaById],
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (persona) => persona?.examplePromptStrings ?? []
    );
    const personaExamplePrompts = useAppSelector((state) => selectPersonaExamplePrompts(state, personaId));

    const [shuffledExamples, setShuffledExamples] = useState<ExampleModel[]>([]);
    const [windowHeight, setWindowHeight] = useState(() => {
        if (scrollViewTargetRef.current) {
            // Subtracting logo/title/subtitle, margin, reload symbol, and 200 extra
            const availableHeight = scrollViewTargetRef.current.clientHeight - 226 - 8 - 32 - 200;

            return availableHeight;
        }
        return 0;
    });

    // Refactored into a separate function so it can be used multiple times
    const reshuffleExamples = () => {
        // Check if chat is using a persona with examples set
        if (personaExamplePrompts.length > 0) {
            const personaSpecificExampleModels: ExampleModel[] =
                personaExamplePrompts.map((text) => ({ text: text, value: text }));
            shuffleArray(personaSpecificExampleModels);
            setShuffledExamples(personaSpecificExampleModels);
            return;
        }
            
        const examplesCopy = [...EXAMPLES];
        shuffleArray(examplesCopy);
        setShuffledExamples(examplesCopy);
    };

    useEffect(() => {
        reshuffleExamples();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    useImperativeHandle(setInitialWindowSizeRef, () => ({
        setInitialWindowSize: () => {
            if (scrollViewTargetRef.current) {
                // Subtracting logo/title/subtitle, margin, reload symbol, and 200 extra
                const availableHeight = scrollViewTargetRef.current.clientHeight - 226 - 8 - 32 - 200;
                setWindowHeight(availableHeight);
            }
        },
    }));

    useEffect(() => {
        const handleResize = () => {
            if (scrollViewTargetRef.current) {
                // Subtracting logo/title/subtitle, margin, reload symbol, and 200 extra
                const availableHeight = scrollViewTargetRef.current.clientHeight - 226 - 8 - 32 - 200;
                setWindowHeight(availableHeight);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [scrollViewTargetRef]);

    // 238px card width, 138px card height (40 less when isSmallWindow=true), 10px gap
    const MAX_POSSIBLE_CARDS_PER_ROW = 5;
    const CARD_PADDING_BOTTOM = 10;
    const CARD_HEIGHT = 138;
    const CARD_HEIGHT_SMALL_WINDOW = 98;

    // Adding CARD_GAP to max height since we are dividing by the sum of the card height and gap
    // and the gap is only present when there is more than one row
    const currentCardHeight = isSmallWindow ? CARD_HEIGHT_SMALL_WINDOW : CARD_HEIGHT;
    let cardsSectionNumRows = Math.floor(windowHeight / (currentCardHeight + CARD_PADDING_BOTTOM));
    cardsSectionNumRows = cardsSectionNumRows > 0 ? cardsSectionNumRows : 1;
    const cardsSectionHeight = ((currentCardHeight + CARD_PADDING_BOTTOM) * cardsSectionNumRows) - 5;

    // Render max cards that would show in the available height,
    // if the width was maxed, and hide the overflow
    const totalRenderedCards = (cardsSectionNumRows) * MAX_POSSIBLE_CARDS_PER_ROW;
    const renderedPrompts = shuffledExamples.slice(0, totalRenderedCards);

    return (
        <div className={classes.container}>
            <ul className={classes.examplesNavList} style={{ maxHeight: `${cardsSectionHeight}px` }}>
                {renderedPrompts.map((x, i) => (
                    <li key={i} className={classes.exampleItemContainer}>
                        <Example
                            text={x.text}
                            value={x.value}
                            onClick={onExampleClicked}
                            isSmallWindow={isSmallWindow}
                        />
                    </li>
                ))}
            </ul>
            <div className={classes.iconWrapper}>
                <Tooltip content={'More examples'} relationship="label">
                    <Button icon={<ArrowClockwise24Regular />} appearance="transparent" onClick={reshuffleExamples} />
                </Tooltip>
            </div>
        </div>
    );
};

export default forwardRef(ExampleList);
